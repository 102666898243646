import React from 'react';
import Panel from '../../../../shared/components/Panel';
import { Row, Col } from 'reactstrap';

const InvestmentsLevel = ({ campaignAmountTotal, amountAveragePerShop, amountAveragePerShopPerDay }) => (
  <Panel md={12} lg={12} xl={12} sm={12} xs={12} title="Budget">

    <div className="dashboard__sales-report">
      <Row>
        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
              <p className="mobile-app-widget__total-stat">{campaignAmountTotal}k€</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Coût total de la campagne</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
              <p className="mobile-app-widget__total-stat">{amountAveragePerShop}€</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Montant moyen/vitrine</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
              <p className="mobile-app-widget__total-stat">{amountAveragePerShopPerDay}€</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Coût moyen / vitrine / jour</h5>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Panel>
);

export default InvestmentsLevel;
