import React from 'react';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
// import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';

const ProfileMain = ({ data }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__avatar">
            <img src={`${data.infos.avatar}`} alt="avatar" />
          </div>
          <div className="profile__data">
            <p className="profile__name">{`${data.infos.firstname} ${data.infos.lastname}`}</p>
            {
              data.infos.fonction && (
                <p className="profile__work">{`${data.infos.fonction}`}</p>
              )
            }
            <p className="profile__contact">{`${data.infos.email}`}</p>
            <p className="profile__contact" dir="ltr">
              <NumberFormat value={data.infos.phone} displayType={'text'} format="## ## ## ## ##" />
            </p>
            {/* <Button color="primary" className="icon profile__btn"><p><MessageTextOutlineIcon /> Message</p></Button> */}
          </div>
        </div>
        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__stat-number">{data.campaigns.pending}</p>
            <p className="profile__stat-title">Campagne{data.campaigns.pending > 1 ? 's' : ''} en cours</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">{data.campaigns.completed}</p>
            <p className="profile__stat-title">
              Campagne{data.campaigns.completed > 1 ? 's' : ''} terminée{data.campaigns.completed > 1 ? 's' : ''}
            </p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">{data.simulations}</p>
            <p className="profile__stat-title">Simulation{data.simulations > 1 ? 's' : ''}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default ProfileMain;
