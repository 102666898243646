import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../../App/Loading';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ShopPhotosCarousel from './components/ShopPhotosCarousel';
import {
  ShopInformations,
  TotalSellInSellOut,
  Audience,
  ShopGeolocation,
  Survey,
  SurveyAnswers
} from './components/dashboardElements'

class CampaignWindowDashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loaded: false,
      collection: {},
      user: {
        ...props.user
      }
    }
    this.campaignid = this.props.match.params.campaignid;
    this.windowid = this.props.match.params.windowid;
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-shop/`, {
      'userid': this.props.user.id,
      'campaignid': this.campaignid,
      'windowid': this.windowid,
    }).then(res => {
      const data = res.data;
      this.setState({
        ...this.state,
        collection: data,
        loaded: true,
        loading: false
      })
    })
  }

  render() {
    const { loaded, loading, collection } = this.state;
    // console.log(this.state);
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    {collection.window.name} <small style={{
                      fontWeight: 'normal',
                      fontSize: 12,
                    }}>({collection.campaignname})</small>
                  </h3>
                  <h3 className="page-subhead subhead">
                    <Link to={`/campaign/${collection.campaignid}`}>
                      Retour à la synthèse de la campagne <ChevronRightIcon />
                    </Link>
                  </h3>
                </Col>
              </Row>

              <Row>
                <ShopInformations 
                  locality={collection.window.locality}
                  location={collection.window.location}
                  newRef={collection.window.newRef}
                />
                {
                  collection.window && (
                    <ShopPhotosCarousel photos={collection.window.photos} />
                  )
                }
                <ShopGeolocation
                  population={collection.window.population.formatted}
                  density={collection.window.density.formatted}
                  commercialDensity={collection.window.commercialDensity.formatted}
                />
              </Row>
              
              <Row>
                <Col lg={3} xl={3} md={12} xs={12} style={{padding: 0}}>
                  <TotalSellInSellOut 
                    sellin={collection.window.sellin}
                    sellout={collection.window.sellout}
                  />
                  <Audience
                    total={collection.window.audience.total}
                    daily={collection.window.audience.daily}
                    weekly={collection.window.audience.weekly}
                  />
                  <Survey
                    satisfaction={collection.window.satisfaction} 
                    salesImpact={collection.window.salesImpact} 
                    imageImpact={collection.window.imageImpact}
                  />
                </Col>
                <Col lg={9} xl={9} md={12} xs={12}>
                  <SurveyAnswers data={collection.survey} />
                </Col>
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignWindowDashboard);