import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Tooltip, Legend,
} from 'recharts';

export default class extends PureComponent {

  render() {
    const colors = ['#f6da6e', '#ff4861', '#b8e985', '#4ce1b6', '#70bbfd', '#007bff'];
    const data = this.props.data.values.map((i, index) => {
      return {
        name: this.props.data.quotes[index],
        value: i,
        fill: colors[index]
      }
    })
    return (
      <div className="project-summary__statistic-chart-wrap">
        <PieChart width={300} height={270}>
          <Tooltip />
          <Pie data={data} dataKey="value" cx="50%" cy={110} innerRadius={60} outerRadius={80} label />
          <Legend />
        </PieChart>
      </div>
    );
  }
}
