import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Statistics2 from './type2';
import Statistics3 from './type3';
import Statistics5 from './type5';
import Table from './Table/MatTable'

const Summary = ({ item }) => (
  <Col md={12} lg={8} xl={8}>
    <Card>
      <CardBody>
        <div className="project-summary">
          <div className="card__title project-summary__card-title">
            <h5 className="bold-text">Question N°{item.id}</h5>
          </div>
          <p 
            className="typography-message" 
            dangerouslySetInnerHTML={{ __html: item.question }}
          ></p><hr />
          {
            item.type === 1 && (
              <React.Fragment>
                {
                  item.answer && (
                    <Table data={item.answer.values} />
                  )
                }
              </React.Fragment>
            )
          }
          {
            item.type === 2 && (
              <Statistics2 data={item.answer} />
            )
          }
          {
            item.type === 3 && (
              <Statistics3 data={item.answer} />
            )
          }
          {
            item.type === 5 && (
              <Statistics5 {...item.answer} />
            )
          }
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default Summary;
