import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Loading from '../App/Loading';
import { Container, Row } from 'reactstrap';
import MatTable from './components/Table/MatTable';

class CampaignsCollection extends React.Component {

  constructor(props) {
    super(props)
    this.listingMapping = {
      '/campaigns/preparation': {
        type: 'preparation',
        title: 'Campagnes en préparation'
      },
      '/campaigns/pending': {
        type: 'pending',
        title: 'Campagnes en cours'
      },
      '/campaigns/completed': {
        type: 'completed',
        title: 'Campagnes terminées'
      }
    }
    this.pageTitle = this.listingMapping[props.location.pathname].title;
    this.state = {
      loaded: false,
      loading: true,
      campaigns: [],
      formatted: [],
      user: {
        ...props.user
      }
    }
  }

  componentDidMount() {
    const listingKey = this.listingMapping[this.props.location.pathname].type;
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-sidebar/`, {
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        ...this.state,
        campaigns: data.campaigns[listingKey].collection,
        formatted: data.campaigns[listingKey].collection.map((item) => {
          return {
            id: item.id,
            campaign: item.name,
            datefrom: item.dateFrom.raw,
            datefromformat: item.dateFrom.formatted,
            dateto: item.dateTo.formatted,
            product: item.product,
            shopscount: item.shops,
            amount: item.totalAmount,
            checklist: item.checklist
          }
        }),
        loading: false,
        loaded: true
      });
    })
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <Row>
              <MatTable 
                history={this.props.history} 
                data={this.state.formatted} 
                title={this.pageTitle} 
                type={this.props.location.pathname}
              />
            </Row>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignsCollection);