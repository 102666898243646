/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';

export default class TopbarNotification extends PureComponent {
  state = {
    collapse: false,
  };

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  handleNotificationPath = (data) => {
    if (data.itemtype === 'documents_devis') {
      this.props.history.push(`/simulation/${data.itemid}`)
      this.props.setNotificationsStatus([data])
      this.props.getNotifications()
      this.toggle()
    }
  }

  handleNotificationsStatus = () => {
    this.props.setNotificationsStatus(this.props.data);
    this.props.setAllRead()
    this.toggle()
  }

  render() {
    const { collapse } = this.state;
    const { data, notificationsCount } = this.props;

    return (
      <div className="topbar__collapse">
        <button className="topbar__btn topbar__btn--mail topbar__btn--new" type="button" onClick={this.toggle}>
          <NotificationsIcon />
          {
            notificationsCount > 0 && (
              <div className="topbar__btn-new-label">
                <div />
              </div>
            )
          }
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Notifications</p>
            <button 
              className="topbar__collapse-button" 
              type="button"
              onClick={this.handleNotificationsStatus}
            >
                Tout marquer comme lu
              </button>
          </div>
          {data.length > 0 && data.map((notification, index) => (
            <div 
              className="topbar__collapse-item" 
              key={index}
              onClick={() => this.handleNotificationPath(notification)}
            >
              <p className="topbar__collapse-name" style={{ fontWeight: `${notification.status > 0 ? 'normal' : 'bold'}` }}>
                {notification.itemtype === 'documents_devis' && 'Nouveau devis'}
              </p>
              <p className="topbar__collapse-message topbar__collapse-message--mail">{notification.message}</p>
              <p className="topbar__collapse-date" style={{ fontWeight: `${notification.status > 0 ? 'normal' : 'bold'}` }}>
                {notification.date}
              </p>
            </div>
          ))}
          <Link 
            className="topbar__collapse-link" 
            to="/notifications" 
          >
            Voir toutes les notifications
          </Link>
        </Collapse>
      </div>
    )
  }
}
