import React from 'react';

export default ({loading, loaded}) => (
  <React.Fragment>
    {
      !loaded && (
        <div 
          className={`load${loading ? '' : ' loaded'}`} 
          style={{
            top: 0,
            left: 240,
            width: 'calc(100vw - 240px)',
            background: '#f2f4f7',
            zIndex: 1,
          }}
        >
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#5b7da5" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )
    }
  </React.Fragment>
)