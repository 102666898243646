import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  render() {
    const { title, icon, path, type, onClick, absolute } = this.props;

    return (
      <React.Fragment>
        {
          absolute ? (
            <a className="topbar__link" href={path} onClick={onClick}>
              <span className={`topbar__link-icon lnr lnr-${icon}`} />
              <p className="topbar__link-title">{title}</p>
            </a>
          ) : (
            <Link className="topbar__link" to={path} onClick={onClick}>
              <span className={`topbar__link-icon lnr lnr-${icon}`} />
              <p className="topbar__link-title">{title}</p>
            </Link>
          )
        }
      </React.Fragment>
    );
  }
}
