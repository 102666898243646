import React, { PureComponent } from 'react';
import { PieChart,  Pie,  Legend,  ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { left: 0 };
  return ({
    ...left,
    width: 150,
    lineHeight: '24px',
    position: 'absolute',
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />
          {entry.value}
        </li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

class Geolocation extends PureComponent {

  render() {
    const {  dir,  paris,  villes_top_10,  villes_top_100,  autres } = this.props;
    const data = [
      { name: 'Paris', value: paris, fill: '#4ce1b6' },
      { name: 'Villes top 10', value: villes_top_10, fill: '#70bbfd' },
      { name: 'Villes top 100', value: villes_top_100, fill: '#f6da6e' },
      { name: 'Autres', value: autres, fill: '#ff4861' }
    ];
    return (
      <Panel lg={5} xl={5} md={12} xs={12} title="Géographie">
        <div dir={dir}>
          <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce">
            <PieChart className="dashboard__chart-pie-container">
              <Pie
                data={data}
                dataKey="value"
                cy={180}
                innerRadius={100}
                outerRadius={130}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text
                      x={x}
                      y={y}
                      fill="#cccccc"
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >
                      {value}%
                    </text>
                  );
                }}
                onMouseMove={this.onMouseMove}
              />
              <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}

export default Geolocation;
