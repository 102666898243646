import React from 'react';
import Panel from '../../../../../shared/components/Panel';

const Audience = ({ total, daily, weekly }) => (
  <Panel md={12} lg={12} xl={12} sm={12} xs={12} title="Audience">
    {/* <div className="dashboard__sales-report"> */}
    <div>
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-title">Trafic piéton total</p>
        <p className="dashboard__sales-report-now">{total || `Non mesuré`}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">Trafic piéton quotidien</p>
        <p className="dashboard__sales-report-now">{daily || `Non mesuré`}</p>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--pink">
        <p className="dashboard__sales-report-title">Trafic piéton hebdomadaire</p>
        <p className="dashboard__sales-report-now">{weekly || `Non mesuré`}</p>
      </div>
    </div>
  </Panel>
);

export default Audience;
