import React from 'react';
import classNames from 'classnames';

export default ({ satisfaction }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': satisfaction <= 5,
    'dashboard__table-orders-amount--low': satisfaction <= 3,
  });
  if (satisfaction > 9) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  } if (satisfaction > 8) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  } if (satisfaction > 7) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  } if (satisfaction > 6) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  if (satisfaction > 5) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  if (satisfaction > 4) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  if (satisfaction > 20) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  if (satisfaction > 3) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  if (satisfaction > 2) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        {
          satisfaction ? (
            <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
          ) : (
            <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
          )
        }
      </div>
    );
  }
  return (
    <div className={amountClass}>N/C
      {
        satisfaction ? (
          <span style={{fontWeight: 'bold'}}>{satisfaction}/10</span>
        ) : (
          <span style={{fontWeight: 'bold'}}>{satisfaction}</span>
        )
      }
    </div>
  );
};