import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Loading from '../App/Loading';
import { Container, Row } from 'reactstrap';
import MatTable from './components/Table/MatTable';

class SelectionsCollection extends React.Component {

  constructor(props) {
    super(props);
    this.campaignmode = this.props.location.pathname === '/campaigns/selections';
    this.state = {
      loaded: false,
      loading: true,
      selections: [],
      user: {
        ...props.user
      }
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-selections/`, {
      'userid': this.props.user.id,
      'campaignmode': this.campaignmode
    }).then(res => {
      const data = res.data;
      this.setState({
        ...this.state,
        loaded: true,
        loading: false,
        selections: data
      })
    })
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <Row>
              <MatTable 
                history={this.props.history} 
                data={this.state.selections} 
                title="Pré-sélections" 
                campaignmode={this.campaignmode}
              />
            </Row>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(SelectionsCollection);