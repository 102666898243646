/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from 'react-vis';
import { Row, Col } from 'reactstrap';

export default class extends PureComponent {

  render() {
    const { scale, scale_values, average } = this.props;
    const data = Object.keys(scale_values).map((key) => {
      return {
        x: key,
        y: scale_values[key]
      }
    })
    return (
      <React.Fragment>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <div className="react-vis">
              <FlexibleWidthXYPlot
                xType="ordinal"
                height={400}
                xDistance={100}
              >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis width={50} />
                <VerticalBarSeries
                  data={data}
                  color="#70bbfd"
                />
              </FlexibleWidthXYPlot>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <p style={{textAlign: 'center'}}>
              <strong>Moyenne:</strong> {average}
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

