import React, { PureComponent } from 'react';
import { Progress } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';

class Score extends PureComponent {
  render() {
    const { children, progress } = this.props;
    return (
      <div className="dashboard__social-stat-item">
        <div className="dashboard__social-stat-title">
          {children}
        </div>
        <div className="dashboard__social-stat-progress">
          <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">
            <p className="progress__label">{progress}%</p>
            {typeof progress === 'number' && <Progress value={progress} />}
          </div>
        </div>
      </div>
    );
  }
}

const Satisfaction = ({ satisfaction, sales, image }) => (
  <Panel md={12} lg={12} xl={12} xs={12} title="Avis commerçants" subhead="Satisfaction et impact">
    <div className="dashboard__weekly-stat">
      <div className="dashboard__social-stat">
        <Score progress={satisfaction}>
          Satisfaction commerçants
        </Score>
        <Score progress={sales}>
          Impact positif sur les ventes
          <p>
            <small>
              <strong>{sales}%</strong> des commerçants considèrent <br />
              que la campagne à eu un impact positif<br /> 
              sur leurs ventes
            </small>
          </p>
        </Score>
        <Score progress={image}>
          Impact positif sur l'image
          <p>
            <small>
              <strong>{image}%</strong> des commerçants considèrent<br /> 
              que la campagne a eu un effet positif<br /> 
              pour l'image de la marque
            </small>
          </p>
        </Score>
      </div>
    </div>
  </Panel>
);

export default Satisfaction;
