import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import TodoList from './components/TodoList';

class Todo extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    notifications: [],
    notificationsCount: 0
  }

  componentDidMount = () => {
    this.getNotifications()
    this.handleNotifications()
  }

  getNotifications = () => {
    if (typeof this.props.authentication.user !== 'undefined') {
      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-all-notifications/`, {
        'userid': this.props.authentication.user.id
      }).then(res => {
        const data = res.data;
        this.setState({ notifications: data.rows, notificationsCount: data.notificationsCount })
      });
    }
  }

  setNotificationsStatus = (n) => {
    if (typeof this.props.authentication.user !== 'undefined') {
      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/set-notifications-status/`, {
        'userid': this.props.authentication.user.id,
        'notifications': n
      }).then(res => {
        const data = res.data;
        // console.log(data);
      });
    }
  }

  handleNotifications = () => {
    let notifications = setInterval(this.getNotifications, 10000)
  }
  render() {
    const {} = this.props;

    const { notifications } = this.state;

    return (
      <Container className="todo-app dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Notifications</h3>
          </Col>
        </Row>
        <Row>
          <Col md={9} xl={10}>
            <TodoList notifications={notifications} />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(Todo);
