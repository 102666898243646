import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    // const { changeToDark, changeToLight } = this.props;
    const { data } = this.props;
    return (
      <div className="sidebar__content">
        <div className="sidebar__avatar" />
        <ul className="sidebar__block">
          <SidebarLink
            icon="home"
            title="Tableau de bord"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
          <SidebarCategory icon="cog" title="Mes simulations">
            <SidebarLink
              dist
              title="Faire une simulation"
              route="/simulateur"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Pré-sélections proposées"
              route="/selections"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Ma liste de simulations"
              route="/simulations"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
          {data &&
            Object.keys(data).map((key) => {
              const item = data[key];
              return (
                <SidebarCategory icon={item.icon} title={item.label} key={key}>
                  {item.selections && (
                    <SidebarLink
                      // title={`${item.selections.label} (${item.selections.count})`}
                      title={`${item.selections.label}`}
                      route={`/${item.slug}/selections`}
                      onClick={this.hideSidebar}
                    />
                  )}
                  {item.preparation && (
                    <SidebarLink
                      // title={`${item.preparation.label} (${item.preparation.count})`}
                      title={`${item.preparation.label}`}
                      route={`/${item.slug}/preparation`}
                      onClick={this.hideSidebar}
                      newLink={item.preparation.collection.length > 0}
                    />
                  )}
                  <SidebarLink
                    // title={`${item.pending.label} (${item.pending.count})`}
                    title={`${item.pending.label}`}
                    route={`/${item.slug}/pending`}
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    // title={`${item.completed.label} (${item.completed.count})`}
                    title={`${item.completed.label}`}
                    route={`/${item.slug}/completed`}
                    onClick={this.hideSidebar}
                  />
                </SidebarCategory>
              );
            })}
          {process.env.DASHBOARD_ENV === "bottl" && (
            <React.Fragment>
              <hr />
              <SidebarCategory
                icon="history"
                title="Points de ventes"
                style={{ color: "#67CEEC", fontWeight: "bold" }}
              >
                <SidebarLink
                  title="Dégustations produit"
                  route="/degustation/produit"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Sac bouteilles"
                  route="/sac/bouteilles"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Vitrines chez Monop'"
                  route="/vitrines/chez/monop"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Vitrines chez Nysa"
                  route="/vitrines/chez/nysa"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Présentoirs sur compt..."
                  route="/presentoirs/sur/comptoir"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory
                icon="history"
                style={{ color: "#CE94FB", fontWeight: "bold" }}
                title="Activation digitale"
              >
                <SidebarLink
                  title="Webinaires"
                  route="/webinaires"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Newsletter"
                  route="/newsletter"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Facebook+Instagram"
                  route="/facebook/instagram"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Publicité sur Bottl.fr"
                  route="/publicite/sur/bottl"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              <SidebarCategory
                icon="history"
                style={{ color: "#FF998E", fontWeight: 'bold' }}
                title="Business intelligence"
              >
                <SidebarLink
                  title="Consulting"
                  route="/consulting"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Panels cavistes"
                  route="/panels/cavistes"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Sondage cavistes"
                  route="/sondage/cavistes"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
