/* eslint-disable react/no-array-index-key */
import React from 'react';
import { 
  Card, CardBody, Table, 
  Col, Button, ButtonToolbar
} from 'reactstrap';

const validate = ({token, id}) => {
  if (typeof window !== 'undefined') {
    document.location.href = `${process.env.REACT_APP_WEBROOT}/resultats/?token=${token}&wplnid=${id}`;
    // window.open(`${process.env.REACT_APP_WEBROOT}/resultats/?token=${token}&wplnid=${id}`);
  }
}

const DevisDetails = (props) => (
  <React.Fragment>
    {/* {console.log(props)} */}
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="project-summary">
            <h2 className="bold-text">Détails de la pré-sélection</h2>
            <p>
              Nous sommes heureux de vous présenter une pré-sélection de commerces qui répondent à vos critères de recherche.
            </p>
            {
              !props.data.hide && (
                <p>
                  Nous accompagnons cette pré-sélection d'une vision budgétaire détaillée présentée ci-dessous. 
                  Lorsque vous aurez validé votre sélection de commerces, le montant de la campagne s'ajustera automatiquement et vous pourrez alors demander un devis.
                </p>
              )
            }
            <div className="card__title project-summary__card-title">
              <Button 
                className="project-summary__btn" 
                color="primary" 
                size="sm" 
                style={{ width: 350, marginLeft: 0 }}
                onClick={() => validate({
                  token: props.data.token,
                  id: props.data.id
                })}
              >
                Voir et valider ma sélection de commerces
              </Button>
            </div>
            {
              !props.data.hide && (
                <React.Fragment>
                  <br />
                  <h3>Présentation de l'enveloppe budgétaire</h3>
                  <hr />
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <p>{props.data.window_count} <span>Vitrines</span></p>
                    </div>
                    {props.data.plan && (
                      <div className="project-summary__stat">
                        <p>{props.data.plan.locationMin}/5 <span>Emplacement</span></p>
                      </div>
                      )
                    }
                    {props.data.plan && (
                      <div className="project-summary__stat">
                        <p>{props.data.plan.imageMin}/5 <span>Image</span></p>
                      </div>
                      )
                    }
                  </div>
                  <React.Fragment>
                    <hr />
                    <Table className="table--bordered notStripped" responsive>
                      <tbody>
                        <tr style={{ border: 'none' }}>
                          <td>Réservation de</td>
                          <td>{props.data.window_count} vitrines</td>
                          <td rowSpan="7" style={{
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            width: '20%'
                          }}>
                            <h4>{props.data.display.total_location_vitrines} €</h4>
                          </td>
                        </tr>

                        <tr>
                          <td>Catégorie(s)</td>
                          { props.data.plan && ( <td>{props.data.plan.shopsCategory_tags}</td> ) }
                        </tr>

                        <tr>
                          <td>Lieu</td>
                          <td>{props.data.location}</td>
                        </tr>

                        <tr>
                          <td>Note d'emplacement</td>
                          { props.data.plan && ( <td>{props.data.plan.locationMin}/5</td> ) }
                        </tr>

                        <tr>
                          <td>Note d'image</td>
                          { props.data.plan && ( <td>{props.data.plan.imageMin}/5</td> ) }
                        </tr>

                        <tr>
                          <td>du</td>
                          <td>{props.data.date_from.formatted}</td>
                        </tr>

                        <tr>
                          <td>au</td>
                          <td>{props.data.date_to.formatted}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr />
                    { props.data.plan && ( <h4>Habillage: {props.data.plan.product_name}</h4> ) }
                    <br />
                    <Table className="table--bordered notStripped" responsive>
                      <tbody>
                        {
                          props.data.display.product_description.map((item, index) => {
                            return (
                              <tr key={`product-line-${index}`} style={{ border: 'none' }}>
                                <td>• {item}</td>
                                {
                                  index === 0 && (
                                    <td rowSpan={props.data.display.product_description.length} style={{
                                      verticalAlign: 'middle',
                                      textAlign: 'center',
                                      width: '20%',
                                      border: 'none'
                                    }}>
                                      <h4>{props.data.display.total_habillage} €</h4>
                                    </td>
                                  )
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    <hr />
                    <h4>Honoraires incluant: </h4>
                    <br />
                    <Table className="table--bordered notStripped" responsive>
                      <tbody>
                        <tr style={{ border: 'none' }}>
                          <td>• Recrutement de commerces</td>
                          <td rowSpan="6" style={{
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            width: '20%'
                          }}>
                            <h4>{props.data.display.total_honoraires} €</h4>
                          </td>
                        </tr>

                        <tr>
                          <td>• Plans techniques de vitrines</td>
                        </tr>

                        <tr>
                          <td>• Gestion des BAT</td>
                        </tr>

                        <tr>
                          <td>• Photos témoins</td>
                        </tr>

                        <tr>
                          <td>• Gestion de campagne</td>
                        </tr>

                        <tr>
                          <td>• Rapport de campagne</td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr />
                    <h4>Options: </h4>
                    <br />
                    {
                      Object.keys(props.data.options_array).length > 0 && (
                        <React.Fragment>
                          {
                            Object.keys(props.data.options_array).map((i, index) => {
                              if (props.data.options_array[i].id === 2) {
                                return (
                                  <Table className="table--bordered notStripped" responsive key={`option-${index}`}>
                                    <tbody>
                                      <tr>
                                        <td>{props.data.options_array[i].label}</td>
                                        <td rowSpan="1" style={{
                                          verticalAlign: 'middle',
                                          textAlign: 'center',
                                          width: '20%',
                                          border: 'none'
                                        }}>
                                          <h4>{props.data.options_array[i].price}</h4>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                )
                              } else {
                                return (
                                  <Table className="table--bordered notStripped" responsive key={`option-${index}`}>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {props.data.options_array[i].label}{` `}
                                          ({props.data.options_array[i].quantity})
                                        </td>
                                        <td rowSpan="1" style={{
                                          verticalAlign: 'middle',
                                          textAlign: 'center',
                                          width: '20%',
                                          border: 'none'
                                        }}>
                                          {
                                            props.data.options_array[i].quantity > 0 ? (
                                              <h4>{props.data.options_array[i].price_raw}.00 €</h4>
                                            ) : (
                                              <h4>0.00 €</h4>
                                            )
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                )
                              }
                            })
                          }
                        </React.Fragment>
                      )
                    }
                    <hr />
                    <div className="invoice__total">
                      <p className="invoice__grand-total">
                        Total: {props.data.price_without_options} €
                      </p>

                      <ButtonToolbar className="invoice__toolbar">
                        <Button 
                          className="project-summary__btn" 
                          color="primary" 
                          size="sm" 
                          style={{ width: 350 }}
                          onClick={() => validate({
                            token: props.data.token,
                            id: props.data.id
                          })}
                        >
                          Voir et valider ma sélection de commerces
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </React.Fragment>
                </React.Fragment>
              )
            }
          </div>
        </CardBody>
      </Card>
    </Col>
  </React.Fragment>
);

export default DevisDetails;
