import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <h4 className="wizard__description">
        1. Si certains commerçants contactés souhaitent obtenir un échantillon de votre produit pour goûter 
        avant de s'engager, êtes-vous d'accord pour leur en faire parvenir ?
      </h4>
      <div className="form__form-group-field">
        <Field
          name="samples"
          component={renderRadioButtonField}
          label="oui"
          className="button"
          radioValue="1"
        />
        <Field
          name="samples"
          component={renderRadioButtonField}
          label="Non"
          className="button"
          radioValue="0"
        />
      </div>
    </div>

    <div className="form__form-group">
      <h4 className="wizard__description">
        2. Quels sont les distributeurs du ou des produits de votre campagne auxquels les commerçants pourront 
        s'approvisionner
      </h4>
      <div className="form__form-group-field">
        <Field
          name="distributors"
          component={renderCheckBoxField}
          label="n'importe qui"
          className="button"
        />
      </div>
    </div>
    <div className="form__form-group">
      <h4 className="wizard__description">
        Ou indiquez une liste de distributeurs
      </h4>
      <div className="form__form-group-field">
        <Field
          name="distributors_list"
          component="textarea"
          type="text"
        />
      </div>
    </div>

    <div className="form__form-group">
      <h4 className="wizard__description">
        3. Si un ou plusieurs commerçants souhaitent que vous réalisiez une animation dans leur magasin pendant la campagne 
        (échantillonnage, dégustation clients), seriez-vous d'accord pour leur en organiser une ?
      </h4>
      <div className="form__form-group-field">
        <Field
          name="event"
          component={renderRadioButtonField}
          label="oui"
          className="button"
          radioValue="1"
        />
        <Field
          name="event"
          component={renderRadioButtonField}
          label="Non"
          className="button"
          radioValue="0"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Précédent</Button>
      <Button color="primary" type="submit" className="next">Suivant</Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
