/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from 'react-vis';
import Panel from '../../../../../shared/components/Panel';

class TotalSellInSellOut extends PureComponent {

  render() {
    const { dir, sellin, sellout } = this.props;
    return (
      <Panel lg={12} xl={12} md={12} xs={12} title="Ventes">
        <div dir={dir}>
          <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce" height={360}>
            <div className="react-vis" dir="ltr">
              <FlexibleWidthXYPlot
                xType="ordinal"
                height={400}
                xDistance={100}
              >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis width={50} />
                <VerticalBarSeries
                  data={[
                    { x: `sell-in (${sellin})`, y: sellin },
                    { x: `sell-out (${sellout})`, y: sellout }
                  ]}
                  color="#70bbfd"
                />
              </FlexibleWidthXYPlot>
            </div>
          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}

export default TotalSellInSellOut;
