import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  authentication,
  registration,
  user,
  alert,
  sidebarReducer,
  themeReducer,
  rtlReducer
} from '../../redux/reducers';

const loggerMiddleware = createLogger();

const reducer = combineReducers({
  form: reduxFormReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  authentication,
  registration,
  alert,
  user
});

const store = createStore(
  reducer, 
  applyMiddleware(
    thunkMiddleware,
    // loggerMiddleware
  )
);

export default store;
