import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Tooltip, Legend,
} from 'recharts';

export default class extends PureComponent {

  render() {
    const colors = ['#4ce1b6', '#ff4861'];
    const data = Object.keys(this.props.data).map((i, index) => {
      return {
        name: i,
        value: this.props.data[i],
        fill: colors[index]
      }
    })
    return (
      <div className="project-summary__statistic-chart-wrap">
        <PieChart width={300} height={270}>
          <Tooltip />
          <Pie data={data} dataKey="value" cx="50%" cy={110} innerRadius={60} outerRadius={80} label />
          <Legend />
        </PieChart>
      </div>
    );
  }
}
