import React from 'react';
import { Progress } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';

const SalesOrderMin = ({ salesOrderMin }) => (
  <Panel md={12} lg={4} xl={4} sm={12} xs={12} title="Pourcentage de commande / min. requis">
    <div className="dashboard__sales-report">
      <div className="progress-wrap progress-wrap--small">
        <p className="dashboard__sales-report-now">{salesOrderMin}%</p>
        <Progress value={salesOrderMin} />
      </div>
    </div>
  </Panel>
);

export default SalesOrderMin;
