import React from 'react';
import { Progress } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';

export default ({ newImplantations, newPremiumImplantations }) => {

  return (
    <Panel md={12} lg={12} xl={12} sm={12} xs={12} title="Nouvelles implantations">
      <div className="progress-wrap progress-wrap--small progress-wrap--pink progress-wrap--label-top">
        <p>{newImplantations.number} nouvelles implantations</p>
        <Progress value={newImplantations.percentage}>
          <p className="progress__label"><strong>{newImplantations.percentage}%</strong></p>
        </Progress>
      </div>
      <div className="progress-wrap progress-wrap--small progress-wrap--label-top">
        <p>{newPremiumImplantations.number} implantations 4 & 5 étoiles</p>
        <Progress value={newPremiumImplantations.percentage}>
          <p className="progress__label"><strong>{newPremiumImplantations.percentage}%</strong></p>
        </Progress>
      </div>
    </Panel>
  )
};
