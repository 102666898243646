import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Alert from '../../../../shared/components/Alert';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      recommandation: false
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleNewInput = () => {
    this.setState({ recommandation: true })
  }

  removeNewInput = () => {
    this.setState({ recommandation: false })
  }

  render() {
    const { handleSubmit, errors } = this.props;
    // console.log(errors.goal);
    return (
      <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <h4 className="wizard__description">
            1. Quel est votre objectif de campagne
          </h4>
          <Alert color="danger" icon isOpen={typeof errors.goal !== 'undefined'}>
            <p>{typeof errors.goal !== 'undefined' && errors.goal}</p>
          </Alert>
          <div className="form__form-group-field">
            <Field
              name="visibility"
              component={renderCheckBoxField}
              label="visibilité"
              className="button"
            />
            <Field
              name="seo"
              component={renderCheckBoxField}
              label="référencements"
              className="button"
            />
            <Field
              name="orders"
              component={renderCheckBoxField}
              label="commandes commerçants"
              className="button"
            />
            <Field
              name="sellout"
              component={renderCheckBoxField}
              label="sell-out"
              className="button"
            />
          </div>
        </div>

        <div className="form__form-group">
          <h4 className="wizard__description">
            2. Quel est l'indicateur clé que vous souhaitez évaluer
          </h4>
          <Alert color="danger" icon isOpen={typeof errors.indicators !== 'undefined'}>
            <p>{typeof errors.indicators !== 'undefined' && errors.indicators}</p>
          </Alert>
          <div className="form__form-group-field">
            <Field
              name="indicators"
              component={renderRadioButtonField}
              label="audience piétonne"
              className="button"
              radioValue="audience"
            />
            <Field
              name="indicators"
              component={renderRadioButtonField}
              label="# de listings"
              className="button"
              radioValue="listings"
            />
            <Field
              name="indicators"
              component={renderRadioButtonField}
              label="ca en €"
              className="button"
              radioValue="ca"
            />
            <Field
              name="indicators"
              component={renderRadioButtonField}
              label="ventes commerçants"
              className="button"
              radioValue="orders"
            />
          </div>
        </div>

        <div className="form__form-group">
          <h4 className="wizard__description">
            3. Quelqu'un en particulier vous a recommandé Message in a Window ?
          </h4>
          <Alert color="danger" icon isOpen={typeof errors.recommandation !== 'undefined'}>
            <p>{typeof errors.recommandation !== 'undefined' && errors.recommandation}</p>
          </Alert>
          <div className="form__form-group-field" onClick={this.handleNewInput}>
            <Field
              name="recommandation"
              component={renderRadioButtonField}
              label="agence"
              className="button"
              radioValue="agence"
            />
            <Field
              name="recommandation"
              component={renderRadioButtonField}
              label="commercant"
              className="button"
              radioValue="commercant"
            />
            <Field
              name="recommandation"
              component={renderRadioButtonField}
              label="enseigne"
              className="button"
              radioValue="enseigne"
            />
            <Field
              name="recommandation"
              component={renderRadioButtonField}
              label="autre"
              className="button"
              radioValue="autre"
            />
          </div>
        </div>

        <div className="form__form-group" style={{display: `${this.state.recommandation ? 'block' : 'none'}`}}>
          <h4 className="wizard__description">
            Précisez
          </h4>
          <div className="form__form-group-field">
            <Field
              name="recommandation_precisions"
              component="input"
              type="text"
            />
          </div>
        </div>

        <div className="form__form-group">
          <h4 className="wizard__description">
            Ou
          </h4>
          <div className="form__form-group-field" onClick={this.removeNewInput}>
            <Field
              name="recommandation"
              component={renderRadioButtonField}
              label="non, personne"
              className="button"
              radioValue="non, personne"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Précédent</Button>
          <Button color="primary" type="submit" className="next">Suivant</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
