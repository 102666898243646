import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Row } from 'reactstrap';
import Loading from '../App/Loading';
import Devis from './components/DevisDetails';

class SelectionDetail extends React.Component {

  constructor(props) {
    super(props);
    this.campaignmode = props.match.path === '/campaigns/selection/:id';
    this.state = {
      loading: true,
      loaded: true,
      details: {}
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-selection-details/`, {
      'selectionid': parseInt(this.props.match.params.id),
      'campaignmode': this.campaignmode,
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        details: data,
        loaded: true,
        loading: false
      })
    })
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          (loaded && Object.keys(this.state.details).length > 0) && (
            <React.Fragment>
              <Row>
                <Devis campaignmode={this.campaignmode} data={this.state.details} />
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(SelectionDetail);