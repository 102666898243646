import React from 'react';
import Panel from '../../../../shared/components/Panel';

export default ({ totalAudience }) => (
  <Panel md={12} lg={3} xl={3} sm={12} xs={12} title="Audience piétonne">
    <div className="mobile-app-widget">
      <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
        <p className="mobile-app-widget__total-stat">{totalAudience}k</p>
      </div>
      <div className="mobile-app-widget__title">
        <h5>Audience touchée</h5>
      </div>
    </div>
  </Panel>
);
