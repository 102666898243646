import React, { PureComponent } from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from 'react-vis';
import Panel from '../../../../shared/components/Panel';

export default class TotalSellInSellOut extends PureComponent {
  render() {
    const { dir, totalSellIn, totalSellOut } = this.props;
    return (
      <Panel lg={12} xl={12} md={12} xs={12} title="Ventes">
        <div dir={dir}>
          <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--commerce">
            <div className="react-vis" dir="ltr">
              <FlexibleWidthXYPlot
                xType="ordinal"
                height={350}
              >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis width={50} />
                <VerticalBarSeries
                  data={[
                    { x: `sell-in (${totalSellIn})`, y: totalSellIn },
                    { x: `sell-out (${totalSellOut})`, y: totalSellOut }
                  ]}
                  color={"#70bbfd"}
                />
              </FlexibleWidthXYPlot>
            </div>
          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}
