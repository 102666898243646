const validate = values => {
  const errors = {}
  if (!values.visibility && !values.seo && !values.orders && !values.sellout) {
    errors.goal = `Veuillez indiquer votre objectif de campagne`
  }
  if (typeof values.indicators === 'undefined') {
    errors.indicators = `Veuillez préciser l'indicateur que vous souhaitez évaluer`
  }
  if (typeof values.recommandation === 'undefined') {
    errors.recommandation = `Veuillez indiquer si Message in a Window vous a été recommandé et si oui merci de préciser`
  }
  if (typeof values.recommandation !== 'undefined') {
    if (values.recommandation !== 'non, personne') {
      if (typeof values.recommandation_precisions === 'undefined') { 
        errors.recommandation = `Veuillez indiquer si Message in a Window vous a été recommandé et si oui merci de préciser`
      }
    }
  }
  
  
  return errors
}

export default validate
