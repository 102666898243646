import React from 'react';
import Panel from '../../../../../shared/components/Panel';

const ShopInformations = ({ locality, location, newRef }) => (
    <Panel md={12} lg={12} xl={3} title="Caractéristiques">
      <div className="dashboard__stat dashboard__stat--budget">
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Ville</p>
          <p className="dashboard__stat-main-number">{locality}</p>
          <hr />
        </div>
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Qualité d'emplacement</p>
          <p className="dashboard__stat-main-number">{location}</p>
          <hr />
        </div>
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Nouveau référencement</p>
          <p className="dashboard__stat-main-number">{newRef}</p>
          <hr />
        </div>
      </div>
    </Panel>
  );

export default ShopInformations;
