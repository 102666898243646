import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { 
  Card, 
  CardBody, 
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

function Action(props) {
  return (
    <UncontrolledDropdown>
      <DropdownToggle className="icon icon--right" outline color="secondary sm" size="sm">
        <p>Actions <ChevronDownIcon /></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu">
        {
          props.status === 4 ? (
            <DropdownItem disabled>
              <span style={{ color: '#cecece' }}>Signer le contrat</span>
            </DropdownItem>
          ) : (
            <React.Fragment>
              <DropdownItem>
                <a href={props.documents.contrat} target="_blank" rel="noopener noreferrer">
                  Télécharger le contrat
                </a>
              </DropdownItem>
              {
                !props.expired && (
                  <DropdownItem>
                    {props.status === 1 && 
                      <Link to={`/simulation/${props.simulation}`}>
                        Signer le contrat
                      </Link>
                    }
                  </DropdownItem>
                )
              }
            </React.Fragment>
          )
        }
        {
          props.campaign_id && (
            <DropdownItem>
              <Link to={`/campaign/${props.campaignid}`}>
                Voir la campagne
              </Link>
            </DropdownItem>
          )
        }
        <DropdownItem>
          <Link to={`/simulation/${props.simulation}`}>
            Voir la simulation
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default class MatTable extends PureComponent {
  state = {
    order: 'asc',
    orderBy: 'datecreate',
    selected: new Map([]),
    data: [],
    page: 0,
    rowsPerPage: 25,
  };

  componentDidMount() {
    this.setState({
      data: this.props.data.map((item) => {
        return {
          id: item.id,
          devisid: item.devis_id,
          campaignid: item.campaign_id,
          nocontrat: item.nocontrat,
          datecreateformat: item.datecreate.formatted,
          datecreate: item.datecreate.raw,
          datesignatureformat: item.datesignature ? item.datesignature.formatted : null,
          datesignature: item.datesignature ? item.datesignature.raw : null,
          validdate: item.valid_date,
          documents: item.documents,
          expired: item.expired,
          status: item.status
        }
      })
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    // this.props.history.push(`/devis/${id}`);
    // const { selected } = this.state;
    // const newSelected = new Map(selected);
    // const value = newSelected.get(id);
    // let isActive = true;
    // if (value) {
    //   isActive = false;
    // }
    // newSelected.set(id, isActive);
    // this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page,
    } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

    return (
      <Col 
        md={12} 
        lg={12} 
      >
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="page-title">{this.props.title}</h3>
            </div>
            <MatTableToolbar
              numSelected={[...selected].filter(el => el[1]).length}
              handleDeleteSelected={this.handleDeleteSelected}
              onRequestSort={this.handleRequestSort}
            />
            <div className="material-table__wrap">
              <Paper>
                <TableContainer style={{ height: 'calc(100vh - 206px)' }}>
                  <Table stickyHeader className="material-table">
                    <MatTableHead
                      numSelected={[...selected].filter(el => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                    />
                    <TableBody>
                      {data
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((d) => {
                          const isSelected = this.isSelected(d.id);
                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              onClick={event => this.handleClick(event, d.id)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={d.id}
                              selected={isSelected}
                            >
                              <TableCell className="material-table__cell material-table__cell-right">
                                <strong>{d.nocontrat}</strong>
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                <span style={{ display: 'none' }}>
                                  {d.datecreate}
                                </span>
                                {d.datecreateformat}
                                {
                                  (d.status === 1 && d.expired)
                                    ? <span className="panel__label badge warning">contrat expiré</span> 
                                  : ''
                                }
                                {
                                  (d.status === 1 && !d.expired) 
                                    ? <span className="panel__label badge">
                                      {`Valide jusqu'au ${d.validdate}`}
                                    </span> 
                                  : ''
                                }
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {
                                  (!d.datesignature) ? (
                                    <span>-</span>
                                  ) : (
                                    <React.Fragment>
                                      <span style={{ display: 'none' }}>
                                        {d.datesignature}
                                      </span>
                                      {d.datesignatureformat}
                                    </React.Fragment>
                                  )
                                }
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                <Action 
                                  documents={d.documents} 
                                  simulation={d.devisid} 
                                  campaignid={d.campaignid} 
                                  status={d.status}
                                  expired={d.expired}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                        <TableRow style={{ maxHeight: 'calc(100vh - 400px)' }}>
                          <TableCell colSpan={5} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Page précédente' }}
              nextIconButtonProps={{ 'aria-label': 'Page suivante' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 100]}
              dir="ltr"
              labelRowsPerPage={''}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              SelectProps={{
                inputProps: { 'aria-label': 'Lignes par page' },
                native: false,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}
