import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../../App/Loading';
import { Col, Container, Row } from 'reactstrap';
import Summary from './components/Summary'

class CampaignSurveyResults extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      survey: null,
      loaded: false
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-survey/`, {
      'campaignid': parseInt(this.props.match.params.campaignid),
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        survey: data,
        loaded: true
      })
    })
  }

  downloadXls = () => {
    // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-survey/`, {
    //   'campaignid': parseInt(this.props.match.params.campaignid),
    //   'userid': this.props.user.id
    // }).then(res => {
    //   const data = res.data;
    //   this.setState({
    //     survey: data,
    //     loaded: true
    //   })
    // })
  }

  render() {
    const { loaded, loading, survey } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          (loaded && this.state.survey) && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    <span style={{ fontWeight: 'normal' }}>
                      Présentation des résultats de votre sondage, campagne « {survey.campaignname} »
                    </span>
                  </h3>
                  <h3 className="page-subhead subhead">
                    <strong>{this.state.survey.numberOfRespondants}</strong> commerçants 
                    sur <strong>{this.state.survey.shopsAccords}</strong> ont 
                    correctement répondus à votre questionnaire 
                    de {Object.keys(this.state.survey.questions).length} questions.
                    <br /><u style={{cursor: 'pointer'}}>Exporter les résultats détaillées en XLS</u>
                  </h3>
                </Col>
              </Row>
              {
                this.state.survey.questions && Object.keys(this.state.survey.questions).map((id, index) => {
                  return (
                    <Row key={`question-${index}`}>
                      <Summary item={this.state.survey.questions[id]} />
                    </Row>
                  )
                })
              }
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignSurveyResults);