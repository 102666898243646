import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  ButtonToolbar,
} from "reactstrap";
class Soon extends React.Component {
  state = {
    loaded: false,
    loading: true,
  };

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12} lg={12} xl={5}>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className="project-summary">
                    {/* <h2 className="bold-text">
                      Bientôt dans votre espace annonceur
                    </h2> */}
                    <div className="card__title project-summary__card-title">
                      Bientôt dans votre espace annonceur
                    </div>
                    <p>
                      Nous sommes en cours de développement pour bien intégrer
                      ce service dans cet espace. En attendant, veuillez
                      utiliser le simulateur qui vous permettra d'obtenir une
                      enveloppe budgétaire en direct !
                    </p>
                    <React.Fragment>
                      <hr />
                      <div className="invoice__total">
                        <p></p>
                        <ButtonToolbar className="invoice__toolbar">
                          <Button
                            color="primary"
                            onClick={
                              typeof window !== undefined &&
                              (() =>
                                window.open(
                                  process.env.REACT_APP_BOTTL_SIMULATOR
                                ))
                            }
                          >
                            Simulateur
                          </Button>
                          {/* <Button color="primary" disabled>
                            Demander un devis
                          </Button> */}
                        </ButtonToolbar>
                      </div>
                    </React.Fragment>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication,
});
export default connect(mapStateToProps)(Soon);
