import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { userActions } from '../../../redux/actions/userActions';

// const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      user: {
        ...props.user
      }
    };
    this.API_ENDPOINT = `http://local.messageinawindow.com/api`;
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse, user } = this.state;
    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={user.avatar} alt="avatar" />
          <p className="topbar__avatar-name">{`${user.firstname} ${user.lastname}`}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Mes informations" icon="list" path="/profile" onClick={this.toggle} />
            <div className="topbar__menu-divider" />
            {
              process.env.DASHBOARD_ENV === 'bottl' ? (
                <TopbarMenuLink 
                  title="Déconnexion" 
                  absolute
                  icon="exit" 
                  path={`${process.env.REACT_APP_BOTTL_WEBROOT}/deconnexion.html`} 
                  onClick={() => this.props.logout()} 
                />
              ) : (
                <TopbarMenuLink 
                  title="Déconnexion" 
                  absolute
                  icon="exit" 
                  path={`${process.env.REACT_APP_WEBROOT}?_a=logout`} 
                  onClick={() => this.props.logout()} 
                />
              )
            }
          </div>
        </Collapse>
      </div>
    );
  }
}

const actionCreators = {
  logout: userActions.logout
};
const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps, actionCreators)(TopbarProfile);