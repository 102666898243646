import React from 'react';
import { connect } from 'react-redux';
import Loading from '../App/Loading';
import { Container, Row } from 'reactstrap';
import Panel from '../../shared/components/Panel';

class CampaignChecklist extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loaded: true
    }
    this.link = `${process.env.REACT_APP_WEBROOT}/m-consulter-edc-campagne.html`;
  }

  render() {
    const { loaded, loading } = this.state;
    const campaignid = parseInt(this.props.match.params.campaignid);

    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Panel 
                  xs={12} 
                  md={12} 
                  lg={12} 
                  color="primary" 
                  title={`Gestion de l'engagement de commande de la campagne`}
                >
                  <iframe 
                    style={{
                      border: 0,
                      width: '100%',
                      height: 'calc(100vh - 250px)'
                    }} 
                    src={`${this.link}?id=${campaignid}&viewport=dashboard&user=${this.props.user.id}&static-redirect=${this.props.user.token}`}
                  >
                  </iframe>
                </Panel>
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignChecklist);