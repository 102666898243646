import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  state = {
    notifications: [],
    notificationsCount: 0
  }

  componentDidMount = () => {
    this.getNotifications()
    // this.handleNotifications()
  }

  getNotifications = () => {
    if (typeof this.props.authentication.user !== 'undefined') {
      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-notifications/`, {
        'userid': this.props.authentication.user.id
      }).then(res => {
        const data = res.data;
        this.setState({ notifications: data.rows, notificationsCount: data.notificationsCount })
      });
    }
  }

  setNotificationsStatus = (n) => {
    if (typeof this.props.authentication.user !== 'undefined') {
      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/set-notifications-status/`, {
        'userid': this.props.authentication.user.id,
        'notifications': n
      }).then(res => {
        const data = res.data;
        // console.log(data);
      });
    }
  }

  setAllRead = () => {
    this.setState({ notificationsCount: 0 })
  }

  handleNotifications = () => {
    let notifications = setInterval(this.getNotifications, 30000)
  }

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    const { notifications, notificationsCount } = this.state;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {
              process.env.DASHBOARD_ENV === 'bottl' ? (
                <a 
                  href={process.env.REACT_APP_BOTTL_WEBROOT}
                  className="topbar__logo"
                ><img src={`/img/logo/logo_${process.env.DASHBOARD_ENV}_dark.png`} /></a>
              ) : (
                <a 
                  href={process.env.REACT_APP_WEBROOT}
                  className="topbar__logo"
                ><img src={`/img/logo/logo_${process.env.DASHBOARD_ENV}_dark.png`} /></a>
              )
            }
          </div>
          <div className="topbar__right">
            <TopbarNotification 
              data={notifications} 
              notificationsCount={notificationsCount}
              setNotificationsStatus={this.setNotificationsStatus}
              history={this.props.history}
              setAllRead={this.setAllRead}
              getNotifications={this.getNotifications}
            />
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(Topbar);