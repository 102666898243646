import React from 'react';
// import { Card, CardBody, Row, Col } from 'reactstrap';
import Carousel from '../../../../shared/components/carousel/CarouselSingle';
import Panel from '../../../../shared/components/Panel';

export default ({ photos }) => (
  <Panel lg={6} xl={6} md={12} xs={12} title="Photos de campagne">
    <Carousel>
      {
        photos.map((item, index) => {
          return (
            <div key={`shop-photo-${index}`}>
              <img src={item.url} alt={`${item.shopname} - ${item.locality}`} />
              <div className="slick-slider__caption">
                <p className="slick-slider__caption-title">{item.shopname}</p>
                <p className="slick-slider__caption-description">
                  {item.locality}
                </p>
              </div>
            </div>
          )
        })
      }
    </Carousel>
  </Panel>
)

// const ShopPhotosCarousel = ({ photos }) => (
//   <React.Fragment>
//     {
//       photos.length > 0 && (
//         <Row>
//           <Col lg={6} xl={6} md={12} xs={12}>
//             <Card>
//               <CardBody>
//                 <div className="card__title">
//                   <h5 className="bold-text">
//                     Diaporama de campagne de ce commerce
//                   </h5>
//                 </div>
//                 <Carousel>
//                   {
//                     photos.map((item, index) => {
//                       return (
//                         <div key={`shop-photo-${index}`}>
//                           <img src={item.url} alt={`${item.shopname} - ${item.locality}`} />
//                           <div className="slick-slider__caption">
//                             <p className="slick-slider__caption-title">{item.shopname}</p>
//                             <p className="slick-slider__caption-description">
//                               {item.locality}
//                             </p>
//                           </div>
//                         </div>
//                       )
//                     })
//                   }
//                 </Carousel>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       )
//     }
//   </React.Fragment>
// );

// export default ShopPhotosCarousel;
