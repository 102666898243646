import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../../App/Loading';
import { Col, Container, Row } from 'reactstrap';
import Summary from './components/Summary'

class CampaignSurveyQuestions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      survey: null,
      loaded: false
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-survey-questions/`, {
      'campaignid': parseInt(this.props.match.params.campaignid),
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        survey: data,
        loaded: true
      })
    })
  }

  render() {
    const { loaded, loading, survey } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          (loaded && this.state.survey) && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    <span style={{ fontWeight: 'normal' }}>
                      Questions de votre sondage, campagne « {survey.campaignname} »
                    </span>
                  </h3>
                  <h3 className="page-subhead subhead">
                    Voici les questions que vous avez renseignées lors de l'élaboration de votre 
                    sondage en ligne. <br />
                    Les commerçants répondent à ces questions à l'issue de leur campagne. <br />
                    Cette page vous permet de consulter/vérifier les questions que vous aviez posées.
                  </h3>
                </Col>
              </Row>
              {
                this.state.survey.questions && Object.keys(this.state.survey.questions).map((id, index) => {
                  return (
                    <Row key={`question-${index}`}>
                      <Summary item={this.state.survey.questions[id]} />
                    </Row>
                  )
                })
              }
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignSurveyQuestions);