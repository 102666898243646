import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../App/Loading';
import { Col, Container, Row } from 'reactstrap';
import Wizard from './components/checklist/WizardForm';
import Panel from '../../shared/components/Panel';
import Alert from '../../shared/components/Alert';

class CampaignChecklist extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      campaign: null,
      loaded: false,
      success: false
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-campaign/`, {
      'campaignid': parseInt(this.props.match.params.id),
      'userid': this.props.user.id
    }).then(res => {
      let data = res.data;
      const campaignCategories = data.categories.split(', ');
      const campaignZones = data.zones.split(', ');
      
      data.categories = campaignCategories.slice(0, 10).join(', ');
      data.zones = campaignZones.slice(0, 10).join(', ');
      this.setState({
        campaign: data,
        loaded: true
      })
    })
  }

  sendData = (values) => {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/checklist/`, {
      'campaignid': parseInt(this.props.match.params.id),
      'userid': this.props.user.id,
      'data': values
    }).then(res => {
      const data = res.data;
      if (typeof data.success !== 'undefined') {
        if (data.success) {
          this.setState({ success: true });
        }
      }
    })
  }

  getBase64 = (data) => {
    return new Promise ((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => {
        resolve(reader.result)
      };
    })
  }

  convertFileData = async (item) => {
    return this.getBase64(item);
  }

  getFileData = async (files) => {
    return Promise.all(files.map(item => this.convertFileData(item)));
  }

  showResults = values => {
    if (typeof values.files !== 'undefined') {
      this.getFileData(values.files).then(data => {
        values.filesBase64 = data;
        this.sendData(values);
      })
    } else {
      this.sendData(values);
    }
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    <span style={{fontWeight: 'normal'}}>Brief de la campagne</span> 
                    {' '}{this.state.campaign.name}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Alert color="success" icon isOpen={this.state.success}>
                    <p>Merci ! Votre brief a bien été enregistré</p>
                  </Alert>
                </Col>
              </Row>
              {
                !this.state.success && (
                  <Wizard onSubmit={this.showResults} />
                )
              }
              <Row>
                <Panel md={12} lg={12} xl={12} title="Détails">
                  <ul className="list list--icon">
                    <li>
                      <span className="lnr lnr-checkmark-circle" />
                      <strong>Campagne {this.state.campaign.type} {this.state.campaign.dates}</strong>
                    </li>
                    <li>
                      <span className="lnr lnr-checkmark-circle" />
                      <strong>Catégories:</strong> {this.state.campaign.categories}
                    </li>
                    <li>
                      <span className="lnr lnr-checkmark-circle" />
                      <strong>Géographie:</strong> {this.state.campaign.zones}
                    </li>
                    <li>
                      <span className="lnr lnr-checkmark-circle" />
                      <strong>Marque:</strong> {this.state.campaign.brand}
                    </li>
                    <li>
                      <span className="lnr lnr-checkmark-circle" />
                      <strong>Commande obligatoire:</strong> {this.state.campaign.edc === 1 ? 'Oui' : 'Non'}
                    </li>
                  </ul>
                </Panel>
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(CampaignChecklist);