import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Panel from '../../../../shared/components/Panel';

export default ({ campaignsSummary }) => (
  <Panel lg={12} title="Tableau récapitulatif de vos dernières campagnes">
    <Table responsive className="table--bordered">
      <thead>
        <tr>
          <th>Campagne</th>
          <th>nb. de commerces</th>
          <th>Audience</th>
          <th>nb. d'implantations</th>
          <th>Coût total</th>
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(campaignsSummary).length > 0 ? (
            <React.Fragment>
              {
                Object.keys(campaignsSummary).map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Link to={`/campaign/${id}`} style={{ color: '#646777', textDecoration: 'underline' }}>
                          {campaignsSummary[id].campaign_name}
                        </Link>
                      </td>
                      <td>{campaignsSummary[id].campaign_shops}</td>
                      <td>{campaignsSummary[id].campaign_audience}k</td>
                      <td>{campaignsSummary[id].campaign_NewImplantations}</td>
                      <td>{campaignsSummary[id].campaign_TotalAmount} €</td>
                    </tr>
                  )
                })
              }
            </React.Fragment>
          ) : (
            <tr colSpan="5">
              <td>Aucune campagne pour le moment</td>
            </tr>
          )
        }
      </tbody>
    </Table>
  </Panel>
);
