import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../App/Loading';
import Alert from '../../shared/components/Alert';
import {
  AbsoluteData,
  TotalSellInSellOut,
  TotalNewImplantations,
  InvestmentsLevel,
  TotalAudience,
  CampaignsSummary,
  CampaignsHistogram
} from './components/dashboardElements'


class CampaignsDashboard extends React.Component {

  constructor() {
    super();

    this.state = {
      loaded: false,
      loading: true,
      onlyMedia: false,
      TotalCampaignsWindows: 0,
      TotalCampaignsWindowsAverage: 0,
      TotalSellIn: 0,
      TotalSellOut: 0,
      TotalAudience: 0,
      TotalAmount: 0,
      TotalAmountAveragePerWindow: 0,
      TotalContactAverage: 0,
      NewImplantations: {
        number: 17,
        percentage: 0
      },
      NewPremiumImplantations: {
        number: 13,
        percentage: 0
      },
      TotalCampaigns: 2,
      CampaignsSummary: {}
    }
  }

  componentDidMount() {
    this.getIndicatorsFromApi();
  }

  getIndicatorsFromApi = () => {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/overall-dashboard-indicators/`, {
      'userid': this.props.authentication.user.id
    }).then(res => {
      const data = res.data;
      const campaigns = data.overallDashboardCampaignsSummary;
      let tradeCampaigns = [];

      if (Object.keys(campaigns).length > 0) {
        tradeCampaigns = Object.keys(campaigns).filter((i) => {
          return campaigns[i].campaign_type === 'TRADE'}
        );
      }

      this.setState({
        loaded: true,
        loading: false,
        onlyMedia: tradeCampaigns.length === 0,
        TotalCampaignsWindows: data ? data.overallDashboardTotalCampaignsWindows : 0,
        TotalCampaignsWindowsAverage: data ? data.overallDashboardTotalCampaignsWindowsAverage : 0,
        TotalSellIn: data ? data.overallDashboardTotalSellIn : 0,
        TotalSellOut: data ? data.overallDashboardTotalSellOut : 0,
        TotalAudience: data ? data.overallDashboardTotalAudience : 0,
        TotalAmount: data ? data.overallDashboardTotalAmount : 0,
        TotalAmountAveragePerWindow: data ? data.overallDashboardTotalAmountAveragePerWindow : 0,
        TotalContactAverage: data ? data.overallDashboardTotalContactAverage : 0,
        NewImplantations: {
          number: data ? data.overallDashboardNewImplantations.number : 0,
          percentage: data ? data.overallDashboardNewImplantations.percentage : 0
        },
        NewPremiumImplantations: {
          number: data ? data.overallDashboardNewPremiumImplantations.number : 0,
          percentage: data ? data.overallDashboardNewPremiumImplantations.percentage : 0
        },
        TotalCampaigns: data ? data.overallDashboardTotalCampaigns : 0,
        CampaignsSummary: data ? data.overallDashboardCampaignsSummary : {},
        campaignsHistogram: data.campaignsHistogram
      })
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    Tableau de bord général
                  </h3>
                </Col>
              </Row>
              {
                this.props.authentication.user.confirmation_email && (
                  <Alert color="info">
                    <p>N'oubliez pas de confirmer votre adresse email</p>
                  </Alert>
                )
              }
              
              <Row>
                <AbsoluteData 
                  totalCampaigns={this.state.TotalCampaigns} 
                  totalCampaignsWindows={this.state.TotalCampaignsWindows} 
                  TotalCampaignsWindowsAverage={this.state.TotalCampaignsWindowsAverage} 
                />
                <CampaignsHistogram media={this.state.onlyMedia} data={this.state.campaignsHistogram} />
              </Row>
              <Row>
                {
                  this.state.onlyMedia ? (
                    <React.Fragment>
                      <TotalAudience 
                        totalAudience={this.state.TotalAudience} 
                      />
                      <InvestmentsLevel 
                        media={this.state.onlyMedia}
                        totalAmount={this.state.TotalAmount}
                        totalAmountAveragePerWindow={this.state.TotalAmountAveragePerWindow}
                        totalContactAverage={this.state.TotalContactAverage}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Col lg={3} xl={3} md={12} xs={12}>
                        <Row>
                          <TotalSellInSellOut 
                            totalSellIn={this.state.TotalSellIn} 
                            totalSellOut={this.state.TotalSellOut}
                          />
                        </Row>
                      </Col>
                      <Col lg={9} xl={9} md={12} xs={12}>
                        <Row>
                          <TotalNewImplantations 
                            newImplantations={this.state.NewImplantations} 
                            newPremiumImplantations={this.state.NewPremiumImplantations} 
                          />
                          <TotalAudience 
                            totalAudience={this.state.TotalAudience} 
                          />
                          <InvestmentsLevel 
                            media={this.state.onlyMedia}
                            totalAmount={this.state.TotalAmount}
                            totalAmountAveragePerWindow={this.state.TotalAmountAveragePerWindow}
                            totalContactAverage={this.state.TotalContactAverage}
                          />
                        </Row>
                      </Col>
                    </React.Fragment>
                  )
                }
              </Row>
              <Row>
                <CampaignsSummary 
                  campaignsSummary={this.state.CampaignsSummary} 
                />
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(CampaignsDashboard);
