import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Loading from '../App/Loading';
import { Container, Row } from 'reactstrap';
import MatTable from './components/Table/MatTable';

class SimulationsCollection extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      loading: true,
      simulations: [],
      user: {
        ...props.user
      }
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-simulations/`, {
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        ...this.state,
        loaded: true,
        loading: false,
        simulations: data
      })
    })
  }

  render() {
    const { loaded, loading } = this.state;
    
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <Row>
              <MatTable history={this.props.history} data={this.state.simulations} title="Simulations" />
            </Row>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(SimulationsCollection);