import React from 'react';
import {
  Card, CardBody, Col
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

const Summary = ({ item }) => (
  <React.Fragment>
    {/* {console.log(item)} */}
    <Col md={12} lg={6} xl={6}>
      <Card>
        <CardBody>
          <div className="project-summary">
            <div className="card__title project-summary__card-title">
              <h5 className="bold-text">Question N°{item.number}</h5>
            </div>
            <p className="typography-message">
              {item.text}
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col md={12} lg={6} xl={6}>
      <Card>
        <CardBody>
          <div className="project-summary">
            <div className="card__title project-summary__card-title">
              <h5 className="bold-text">Réponse(s) possible(s)</h5>
            </div>
            {
              item.type === 1 && (
                <form className="form form--vertical">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="disableInput"
                        component="input"
                        type="text"
                        placeholder="Inscrivez ici votre réponse (champ libre)"
                        disabled
                      />
                    </div>
                  </div>
                </form>
              )
            }
            {
              item.type === 2 && (
                <React.Fragment>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="radio_disabled"
                        component={renderRadioButtonField}
                        label="Oui"
                        radioValue="1"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="radio_disabled"
                        component={renderRadioButtonField}
                        label="Non"
                        radioValue="1"
                        disabled
                      />
                    </div>
                  </div>
                </React.Fragment>
              )
            }
            {
              item.type === 3 && (
                <React.Fragment>
                  {
                    item.labels.map((i, index) => {
                      return (
                        <div className="form__form-group" key={`item3-${index}`}>
                          <div className="form__form-group-field">
                            <Field
                              name="radio_disabled"
                              component={renderRadioButtonField}
                              label={i}
                              radioValue="1"
                              disabled
                            />
                          </div>
                        </div>
                      )
                    })  
                  }
                </React.Fragment>
              )
            }
            {
              item.type === 4 && (
                <React.Fragment>
                  {
                    item.labels.map((i, index) => {
                      return (
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="checkbox_disable"
                              component={renderCheckBoxField}
                              label={i}
                              disabled
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                </React.Fragment>
              )
            }
            {
              item.type === 5 && (
                <p>
                  Choix de 0 (signifiant "{item.labels[0]}") 
                  à 10 (signifiant "{item.labels[1]}")
                </p>
              )
            }
          </div>
        </CardBody>
      </Card>
    </Col>
  </React.Fragment>
);

export default reduxForm({
  form: 'questions_form',
})(Summary);
