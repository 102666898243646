import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
// import renderDropZoneField from '../../../../shared/components/form/DropZone';
import renderDropZoneMultipleField from '../../../../shared/components/form/DropZoneMultiple';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <h4 className="wizard__description">
        1. Pourriez-vous nous décrire le ou les produits qui feront l'objet de la campagne et leur(s) spécificités ?
      </h4>
      <div className="form__form-group-field">
        <Field
          name="product_description"
          component="textarea"
          type="text"
        />
      </div>
    </div>

    <div className="form__form-group">
      <h4 className="wizard__description">
        2. Site(s) internet qui nous permettrai(en)t d'en savoir encore plus
      </h4>
      <div className="form__form-group-field">
        <Field
          name="websites"
          component="textarea"
          type="text"
        />
      </div>
    </div>

    <div className="form__form-group">
      <h4 className="wizard__description">
        3. Attachez tous documents que vous jugez utiles de nous partager
      </h4>
      <div className="form__form-group-field">
        <Field
          name="files"
          component={renderDropZoneMultipleField}
          customHeight
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Précédent</Button>
      <Button color="primary" type="submit" className="next">Suivant</Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
