import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../App/Loading';
import { Col, Container, Row, Button } from 'reactstrap';
import ShopsTable from './shops/components/ShopsTable'
import Panel from '../../shared/components/Panel';
import {
  NewImplantations,
  TotalAudience,
  Satisfaction,
  SalesOrderMin,
  Sales,
  Geolocation,
  InvestmentsLevel,
  AbsoluteData,
  Documents,
} from './components/dashboardElements'

class Campaign extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      campaign: null,
      loaded: false,
      campaign: null,
      shops: []
    }

    this.status = {
      '__CAMPAIGN_STATUS_HORSQUOTA': 99,
      '__CAMPAIGN_STATUS_ANNULEE': 12,
      '__CAMPAIGN_STATUS_CLOTUREE': 11,
      '__CAMPAIGN_STATUS_A_DEBRIEFER': 10,
      '__CAMPAIGN_STATUS_EN_COURS': 9,
      '__CAMPAIGN_STATUS_BAT_VALIDE': 8,
      '__CAMPAIGN_STATUS_OPERATIONS_A_PLANIFIER': 7,
      '__CAMPAIGN_STATUS_OPTIONS_CHOISIES': 6,
      '__CAMPAIGN_STATUS_COMMERCES_VALIDES': 5,
      '__CAMPAIGN_STATUS_PRESENTATION_COMMERCES': 4,
      '__CAMPAIGN_STATUS_RECHERCHE_COMMERCES': 3,
      '__CAMPAIGN_STATUS_BRIEF_CONTROLE': 2,
      '__CAMPAIGN_STATUS_BRIEF_A_CONTROLER': 1
    }
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-campaign/`, {
      'campaignid': parseInt(this.props.match.params.id),
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      // console.log(data);
      this.setState({
        campaign: data,
        loaded: true
      })
    });

    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/campaign/get-shops/`, {
      'userid': this.props.user.id,
      'campaignid': parseInt(this.props.match.params.id)
    }).then(res => {
      const data = res.data;
      this.setState({
        ...this.state,
        shops: data.shops
      })
    })
  }

  campaignBriefAccess = () => {
    this.props.history.push(`/checklist/campaign/${this.state.campaign.id}`);
  }

  campaignSurveyAccess = () => {
    this.props.history.push(`/survey/creation/${this.state.campaign.id}`);
  }

  campaignEdcAccess = () => {
    this.props.history.push(`/edc/visualisation/${this.state.campaign.id}`);
  }

  render() {
    const { loaded, loading, campaign } = this.state;
    const campaignId = this.props.match.params.id;
    console.log(campaign);
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              {
                campaign.status < this.status.__CAMPAIGN_STATUS_RECHERCHE_COMMERCES ? (
                  <Row>
                    <Panel
                      xs={12}
                      md={12}
                      lg={6}
                      color="primary"
                      title={`Compléter le brief de campagne`}
                    >
                      <p className="typography-message">
                        La campagne <strong>"{campaign.name}"</strong> est actuellement en cours de préparation.<br />
                        {
                          this.state.campaign.campaignBrief ? (
                            <>Vous avez déjà complété votre brief.</>
                          ) : (
                              <>Afin de nous aider à répondre au mieux à vos attentes, vous pouvez répondre à quelques questions.</>
                            )
                        }
                      </p>
                      <hr />
                      {
                        !this.state.campaign.campaignBrief && (
                          <p style={{ textAlign: 'center', paddingTop: 20 }}>
                            <Button color="primary" outline size="sm" onClick={this.campaignBriefAccess}>
                              Je complète mon brief
                            </Button>
                          </p>
                        )
                      }
                    </Panel>
                    <Panel
                      xs={12}
                      md={12}
                      lg={6}
                      color="primary"
                      title={`Renseigner les informations nécessaires pour le sondage`}
                    >
                      <p className="typography-message">
                        Dès la fin de votre campagne, votre questionnaire sera adressé aux commerçants ayant accueilli
                        votre visuel dans leur vitrine. Prenez le temps de construire les questions afin de recueillir
                        les informations qui vous intéressent.
                      </p>
                      <hr />
                      {
                        !this.state.campaign.sondageCompleted && (
                          <p style={{ textAlign: 'center', paddingTop: 20 }}>
                            <Button color="primary" outline size="sm" onClick={this.campaignSurveyAccess}>
                              Je complète le questionnaire
                            </Button>
                          </p>
                        )
                      }
                    </Panel>
                    {
                      campaign.edc === 1 && (
                        <Panel
                          xs={12}
                          md={12}
                          lg={6}
                          color="primary"
                          title={`Valider mon engagement de commande`}
                        >
                          {/* <p className="typography-message"></p>
                          <hr /> */}
                          {
                            !this.state.campaign.campaignBrief && (
                              <p style={{ textAlign: 'center', paddingTop: 20 }}>
                                <Button color="primary" outline size="sm" onClick={this.campaignEdcAccess}>
                                  Je valide mon engagement de commande
                                </Button>
                              </p>
                            )
                          }
                        </Panel>
                      )
                    }
                  </Row>
                ) : (
                    <React.Fragment>
                      <Row>
                        <Col md={12}>
                          <h3 className="page-title">
                            <span style={{ fontWeight: 'normal' }}>Campagne</span> {campaign.name}
                          </h3>
                          <h3 className="page-subhead subhead">
                            {campaign.dates} <small>({campaign.totalDays} jours)</small>
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <AbsoluteData
                          totalShops={campaign.totalShops}
                          location={campaign.locationAverage}
                          image={campaign.imageAverage}
                          campaignId={campaignId}
                        />
                        {campaign.geolocation && (
                          <Geolocation
                            paris={campaign.geolocation.paris}
                            villes_top_10={campaign.geolocation.villes_top_10}
                            villes_top_100={campaign.geolocation.villes_top_100}
                            autres={campaign.geolocation.autres}
                          />
                        )}
                        <Documents campaign={this.state.campaign} campaignid={campaignId} />
                      </Row>

                      <Row>
                        <Col md={12} lg={4} xl={4} xs={12} style={{ padding: 0 }}>
                          {
                            campaign.audience && (
                              <TotalAudience
                                total={campaign.audience.total}
                                daily={campaign.audience.daily}
                                contact={campaign.contactAmountTotal}
                                amountDaily={campaign.audience.amountDaily}
                              />
                            )
                          }
                        </Col>
                        <Col md={12} lg={8} xl={8} xs={12} style={{ padding: 0 }}>
                          <Satisfaction
                            satisfaction={campaign.satisaction}
                            sales={campaign.sales_impact}
                            image={campaign.image_impact}
                          />
                          {
                            campaign.newImplantations && (
                              <NewImplantations
                                newImplantations={campaign.newImplantations}
                                newImplantationsPremium={campaign.newImplantationsPremium}
                              />
                            )
                          }
                        </Col>
                      </Row>


                      <Row>
                        <Sales
                          sellin={campaign.sellout}
                          sellout={campaign.sellin}
                          orderaverage={campaign.orderAveragePerShop
                            ? campaign.orderAveragePerShop.number
                            : 0}
                        />
                        <SalesOrderMin salesOrderMin={campaign.orderPercentagePerMin} />
                      </Row>

                      <Row>
                        <InvestmentsLevel
                          campaignAmountTotal={campaign.campaignAmountTotal}
                          amountAveragePerShop={campaign.amountAveragePerShop}
                          amountAveragePerShopPerDay={campaign.amountAveragePerShopPerDay}
                        />
                      </Row>

                      {
                        Object.keys(this.state.shops).length > 0 && (
                          <React.Fragment>
                            <Row>
                              <ShopsTable items={this.state.shops} campaignid={campaignId} />
                            </Row>
                          </React.Fragment>
                        )
                      }
                    </React.Fragment>
                  )
              }
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(Campaign);
