import React from 'react';
import { connect } from 'react-redux';
// import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../App/Loading';

class Simulator extends React.Component {
  state = {
    loading: true,
    loaded: true
  }

  componentDidMount() {
    // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-simulation-details/`, {
    //   'simulationid': parseInt(this.props.match.params.id),
    //   'userid': this.props.user.id
    // }).then(res => {
    //   const data = res.data;
    //   this.setState({
    //     details: data,
    //     loaded: true,
    //     loading: false
    //   })
    // })
  }

  render() {
    const { loaded, loading } = this.state;
    const { user } = this.props;
    return (
      <Container className="listing" style={{paddingTop: 50}}>
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12} style={{padding: 0}}>
                  <iframe 
                    style={{
                      border: 0,
                      width: '100%',
                      height: 'calc(100vh - 55px)'
                    }} 
                    src={`${process.env.REACT_APP_SIMULATOR}?viewport=dashboard&_t=${user.token}`}
                  >
                  </iframe>
                </Col>
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(Simulator);