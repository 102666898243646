import React from 'react';
import Panel from '../../../../shared/components/Panel';

const TotalAudience = ({ total, daily, contact, amountDaily }) => (
  <Panel md={12} lg={12} xl={12} title="Audience" fixedheight>
    <div className="dashboard__stat dashboard__stat--budget">
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Trafic piéton total est.</p>
        <p className="dashboard__stat-main-number">{total} k</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Trafic piéton quotidien</p>
        <p className="dashboard__stat-main-number">{daily}</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Coût au contact</p>
        <p className="dashboard__stat-main-number">{contact}€</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Coût quotidien</p>
        <p className="dashboard__stat-main-number">{amountDaily}€</p>
      </div>
    </div>
  </Panel>
);

export default TotalAudience;
