import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import Panel from '../../../../shared/components/Panel';

const NewImplantations = ({ newImplantations, newImplantationsPremium }) => {
  const data01 = [
    { value: newImplantations.percentage, fill: '#b8e986' },
    { value: (100 - newImplantations.percentage), fill: '#eeeeee' }
  ];

  const data02 = [
    { value: newImplantationsPremium.percentage, fill: '#ff4861' },
    { value: (100 - newImplantationsPremium.percentage), fill: '#eeeeee' }
  ];
  return (
    <Panel md={12} lg={12} xl={12} xs={12} title="Nouvelles implantations">
      <div className="dashboard__weekly-stat">
        <div className="dashboard__weekly-stat-chart">
          <div className="dashboard__weekly-stat-chart-item">
            <div className="dashboard__weekly-stat-chart-pie">
              <ResponsiveContainer>
                <PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
                  <Pie
                    data={data01}
                    dataKey="value"
                    cx={50}
                    cy={50}
                    innerRadius={46}
                    outerRadius={55}
                  />
                </PieChart>
              </ResponsiveContainer>
              <p className="dashboard__weekly-stat-label" style={{ color: '#b8e986' }}>
                {newImplantations.percentage}%
              </p>
            </div>
            <div className="dashboard__weekly-stat-info">
              <p><strong>{newImplantations.number}</strong> Nouvelles implantations</p>
            </div>
          </div>
          <div className="dashboard__weekly-stat-chart-item">
            <div className="dashboard__weekly-stat-chart-pie">
              <ResponsiveContainer>
                <PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
                  <Pie
                    data={data02}
                    dataKey="value"
                    cx={50}
                    cy={50}
                    innerRadius={46}
                    outerRadius={55}
                  />
                </PieChart>
              </ResponsiveContainer>
              <p className="dashboard__weekly-stat-label" style={{ color: '#ff4861' }}>
                {newImplantationsPremium.percentage}%
              </p>
            </div>
            <div className="dashboard__weekly-stat-info">
              <p><strong>{newImplantationsPremium.number}</strong> Nouvelles implantations 4&5 étoiles</p>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  )
};

export default NewImplantations;
