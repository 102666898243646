import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';

class LogInForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
      username: '',
      password: '',
      submitted: false
    };
  }
  
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.login(email, password);
    }
  }

  render() {
    const { showPassword, submitted, email, password } = this.state;
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input 
              type="email" 
              name="email" 
              onChange={this.handleChange} 
            />
          </div>
          {submitted && !email && <div className="help-block">Veuillez entrer votre adresse email</div>}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Mot de passe</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input 
              type={showPassword ? 'text' : 'password'} 
              type="password" 
              name="password" 
              onChange={this.handleChange} 
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          {submitted && !password && <div className="help-block">Veuillez entrer votre mot de passe</div>}
          <div className="account__forgot-password">
            {
              process.env.DASHBOARD_ENV === 'bottl' ? (
                <a href={`${process.env.REACT_APP_BOTTL_WEBROOT}/auth-mot-de-passe-oublie.html`} >Mot de passe oublié ?</a>
              ) : (
                <a href={`${process.env.REACT_APP_WEBROOT}?_a=retrieve-password`} >Mot de passe oublié ?</a>
              )
            }
          </div>
        </div>
        <button className="btn btn-primary account__btn account__btn--small">
          {
            this.props.loggingIn ? `Connexion en cours...` : `Se connecter`
          }
        </button>
        {
          process.env.DASHBOARD_ENV === 'bottl' ? (
            <a 
              className="btn btn-outline-primary account__btn account__btn--small" 
              href={`${process.env.REACT_APP_BOTTL_WEBROOT}/inscription.html`} 
            >
              Créer un compte
            </a>
          ) : (
            <a 
              className="btn btn-outline-primary account__btn account__btn--small" 
              href={`${process.env.REACT_APP_WEBROOT}?_a=register`} 
            >
              Créer un compte
            </a>
          )
        }
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedLoginPage = connect(mapStateToProps, actionCreators)(LogInForm);
export { connectedLoginPage as LogInForm };