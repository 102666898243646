import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <h4 className="wizard__description">
        1. Si un ou plusieurs commerçants venai(ent) à mutiler le décor dans sa vitrine (découpe, décrochage partiel 
        ou total, etc...), que préferez-vous que nous fassions ?
      </h4>
      <div className="form__form-group-field radio-double-height radio-large">
        <Field
          name="cgu"
          component={renderRadioButtonField}
          label="Gérer directement le(s) cas avec bonne intelligence et suivre les CGU"
          className="button"
          radioValue="0"
        />
        <Field
          name="cgu"
          component={renderRadioButtonField}
          label="Vous contacter au cas pas cas pour décider et éviter toute difficulté commerciale"
          className="button"
          radioValue="1"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Précédent</Button>
      <Button color="primary" type="submit" className="next">Suivant</Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
