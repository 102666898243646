import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';

import CampaignsDashboard from '../Campaigns/index';
import CampaignsCollection from '../Campaigns/collection';

import Campaign from '../Campaign/index';
import CampaignWindowDashboard from '../Campaign/shops/dashboard';
import CampaignChecklist from '../Campaign/checlist';
import CampaignSurveyQuestions from '../Survey/Questions/index';
import CampaignSurveyResults from '../Survey/Results/index';
import CampaignSurveyCreation from '../Survey/Creation/index';
import EdcResults from '../Campaign/edcResults';
import EdcVisualisation from '../Campaign/edcVisualisation';

import DevisCollection from '../Devis/collection';
import ContratsCollection from '../Contrats/collection';

import SelectionsCollection from '../Selections/collection';
import SelectionDetail from '../Selection/index';

import SimulationsCollection from '../Simulations/collection';
import SimulationDetail from '../Simulation/index';
import Simulator from '../Simulation/Simulator';

import Profile from '../Profile/index';
import Notifications from '../Notifications/index';

import Soon from "../Soon/Index";
import Errors from '../Error/index';

const Pages = (props) => (
  <Switch>
    {localStorage.getItem('user') ? (
      <React.Fragment>
        <Route exact path="/" component={CampaignsDashboard} />
        <Route path="/dashboard" component={CampaignsDashboard} />
        <Route path="/campaigns/selections" component={SelectionsCollection} />
        <Route path="/campaigns/selection/:id" component={SelectionDetail} />
        <Route path="/campaigns/preparation" component={CampaignsCollection} />
        <Route path="/campaigns/pending" component={CampaignsCollection} />
        <Route path="/campaigns/completed" component={CampaignsCollection} />

        <Route path="/campaign/:id" component={Campaign} />
        <Route path="/checklist/campaign/:id" component={CampaignChecklist} />
        <Route path="/shop/:windowid/:campaignid/report" component={CampaignWindowDashboard} />
        <Route path="/survey/questions/:campaignid" component={CampaignSurveyQuestions} />
        <Route path="/survey/results/:campaignid" component={CampaignSurveyResults} />
        <Route path="/survey/creation/:campaignid" component={CampaignSurveyCreation} />
        <Route path="/edc/results/:campaignid" component={EdcResults} />
        <Route path="/edc/visualisation/:campaignid" component={EdcVisualisation} />

        <Route path="/contrats/pending" component={ContratsCollection} />
        <Route path="/contrats/completed" component={ContratsCollection} />
        
        <Route path="/devis/pending" component={DevisCollection} />
        <Route path="/devis/completed" component={DevisCollection} />

        <Route path="/selections" component={SelectionsCollection} />
        <Route path="/selection/:id" component={SelectionDetail} />

        <Route path="/simulations" component={SimulationsCollection} />
        <Route path="/simulation/:id" component={SimulationDetail} />
        <Route path="/simulateur" component={Simulator} />

        <Route path="/degustation/produit" component={Soon} />
        <Route path="/sac/bouteilles" component={Soon} />
        <Route path="/vitrines/chez/monop" component={Soon} />
        <Route path="/vitrines/chez/nysa" component={Soon} />
        <Route path="/presentoirs/sur/comptoir" component={Soon} />

        <Route path="/webinaires" component={Soon} />
        <Route path="/newsletter" component={Soon} />
        <Route path="/facebook/instagram" component={Soon} />
        <Route path="/publicite/sur/bottl" component={Soon} />

        <Route path="/consulting" component={Soon} />
        <Route path="/panels/cavistes" component={Soon} />
        <Route path="/sondage/cavistes" component={Soon} />

        <Route path="/profile" component={Profile} />
        <Route path="/notifications" component={Notifications} />
        
        <Route path="/error" component={Errors} />
      </React.Fragment>
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )}
    
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
