import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Panel from '../../../../shared/components/Panel';
import { AudienceAmount, SatisfactionAmount } from './gauges';

const ShopsTable = ({ items, campaignid }) => (
  <Panel xl={12} lg={12} md={12} title="Commerces de la campagne">
    <Table responsive striped className="dashboard__table-orders notStripped">
      <thead>
        <tr>
          <th>Commerce</th>
          <th>Audience</th>
          <th>Nouvelle implantation</th>
          <th>Commandes produits</th>
          <th>Satisfaction</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(items).map((id, index) => (
          <tr key={index}>
            <td className="dashboard__table-orders-title">
              <div className="dashboard__table-orders-img-wrap">
                <div className="dashboard__table-orders-img" style={{ backgroundImage: `url(${items[id].image})` }} />
              </div>
              <Link 
                className="catalog-item__link" 
                to={`/shop/${items[id].features.windowid}/${campaignid}/report`}
                style={{ color: '#646777', textDecoration: 'underline' }}
              >
                {items[id].name} 
              </Link>
            </td>
            <td className="dashboard__table-orders-total" dir="ltr" style={{width: '15%'}}>
              {
                items[id].shopAudience.audienceTotale ? (
                  <AudienceAmount audience={items[id].shopAudience.audienceTotale / items[id].features.nombreDeJours} />
                ) : (
                  'N/C'
                )
              }
            </td>
            <td style={{width: '15%'}}>
              {items[id].newRef}
            </td>
            <td style={{width: '15%'}}>
              {items[id].features.commandeProduits || 0}
            </td>
            <td className="dashboard__table-orders-total" dir="ltr" style={{width: '15%'}}>
              <SatisfactionAmount satisfaction={items[id].satisfaction} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Panel>
);

export default ShopsTable;
