import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../App/Loading';
import Devis from './components/DevisDetails';

class SimulationDetail extends React.Component {
  state = {
    loading: true,
    loaded: true,
    details: {}
  }

  componentDidMount() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-simulation-details/`, {
      'simulationid': parseInt(this.props.match.params.id),
      'userid': this.props.user.id
    }).then(res => {
      const data = res.data;
      this.setState({
        details: data,
        loaded: true,
        loading: false
      })
    })
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          (loaded && Object.keys(this.state.details).length > 0) && (
            <React.Fragment>
              {/* <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    <span style={{fontWeight: 'normal'}}>Simulation</span> {this.state.details.campaign_name}
                  </h3>
                </Col>
              </Row> */}
              <Row>
                <Devis data={this.state.details} />
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(SimulationDetail);