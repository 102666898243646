import React from 'react';
import Panel from '../../../../shared/components/Panel';
import { Col } from 'reactstrap';

export default ({ totalAmount, totalAmountAveragePerWindow, totalContactAverage, media }) => (
  <React.Fragment>
    <Panel md={12} lg={9} xl={9} sm={12} xs={12} title="Niveau des investissements">
      <div className="dashboard__sales-report">
        <Col md={12} xl={4} lg={4} xs={12} style={{padding: 0}}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{totalAmount} €</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Montant cumulé</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12} style={{ padding: 0 }}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{totalAmountAveragePerWindow} €</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Montant moyen/vitrine</h5>
            </div>
          </div>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12} style={{ padding: 0 }}>
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat">{totalContactAverage} €</p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>Coût moyen au contact</h5>
            </div>
          </div>
        </Col>
      </div>
    </Panel>
  </React.Fragment>
);
