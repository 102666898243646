import React from 'react';
import Panel from '../../../../shared/components/Panel';

export default ({ totalCampaigns, totalCampaignsWindows, TotalCampaignsWindowsAverage }) => (
  <Panel md={12} lg={12} xl={3} title="Campagnes et vitrines">
    <div className="dashboard__stat dashboard__stat--budget">
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Nb. de campagnes réalisées</p>
        <p className="dashboard__stat-main-number">{totalCampaigns}</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Nb. total de vitrines décorées</p>
        <p className="dashboard__stat-main-number">{totalCampaignsWindows}</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Nb. moyen de vitrines/campagne</p>
        <p className="dashboard__stat-main-number">{TotalCampaignsWindowsAverage}</p>
      </div>
    </div>
  </Panel>
);
