import React from 'react';
import Panel from '../../../../shared/components/Panel';
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
  DiscreteColorLegend
} from 'react-vis';

const colors = {
  '2017': '#ff4852',
  '2018': '#f6da6e',
  '2019': '#70bbfd',
  '2020': '#4ce1b6'
};

const CampaignsHistogram = ({ media, data }) => (
  <Panel lg={12} xl={9} md={12} xs={12} title="Nombre de vitrines décorées / mois">
    <div className="react-vis" dir="ltr">
      <FlexibleWidthXYPlot
        xType="ordinal"
        height={430}
        xDistance={100}
      >
        <DiscreteColorLegend
          style={{position: 'absolute', right: '50px', top: '-50px'}}
          orientation="horizontal"
          items={[
            {
              title: '2019',
              color: '#70bbfd'
            },
            {
              title: '2020',
              color: '#c88ffa'
            }
          ]}
        />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <VerticalBarSeries
          data={
            Object.keys(data).map((key) => {
              return { 
                x: key, 
                y: data[key][0] ? data[key][0].value : 0
              }
            })
          }
          color="#70bbfd"
        />
        <VerticalBarSeries
          data={
            Object.keys(data).map((key) => {
              return {
                x: key,
                y: data[key][1] ? data[key][1].value : 0
              }
            })
          }
          color="#c88ffa"
        />
      </FlexibleWidthXYPlot>
    </div>
  </Panel>
);

export default CampaignsHistogram;
