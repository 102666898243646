import React from 'react';
import Panel from '../../../../shared/components/Panel';

const AbsoluteData = ({ totalShops, location, image }) => (
  <Panel md={12} lg={12} xl={3} title="Commerces">
    <div className="dashboard__stat dashboard__stat--budget">
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Nb. de commerces</p>
        <p className="dashboard__stat-main-number">{totalShops}</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Emplacement moyen</p>
        <p className="dashboard__stat-main-number">{location}</p>
        <hr />
      </div>
      <div className="dashboard__stat-main">
        <p className="dashboard__stat-main-title">Image moyenne</p>
        <p className="dashboard__stat-main-number">{image}</p>
        <hr />
      </div>
    </div>
  </Panel>
);

export default AbsoluteData;
