import uuid from 'uuid';

export const userService = {
  login,
  tokenLogin,
  logout,
  register,
  update
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: username, password: password, token: uuid() })
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/login/`, requestOptions).then(handleResponse).then(user => {
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  });
}

function tokenLogin(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: token })
  };
  
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/login/token/`, requestOptions).then(handleResponse).then(user => {
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  });

  // localStorage.removeItem('user');
  // switch (process.env.DASHBOARD_ENV) {
  //   case 'bottl' :
  //     return fetch(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/credentials/login/token/`, requestOptions).then(handleResponse).then(user => {
  //       localStorage.setItem('user', JSON.stringify(user));
  //       return user;
  //     });
  //   break;
  //   case 'mw' :
  //     return fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/login/token/`, requestOptions).then(handleResponse).then(user => {
  //       localStorage.setItem('user', JSON.stringify(user));
  //       return user;
  //     });
  //   break;
  // }
}

function logout() {
  localStorage.removeItem('user');
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${user.id}`, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (data.error) {
      if (data.error === 403) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}