import React, { Component } from 'react';
import classNames from 'classnames';
import TodoItem from './TodoItem';

class TodoList extends Component {

  static defaultProps = {
    completed: false,
  };

  render() {
    const { notifications, completed } = this.props;

    const todoListClass = classNames({
      'todo__item-comleted': completed,
    });

    return (
      notifications.map(notification => (
        <div xs={12} key={notification.id} className={todoListClass}>
          <TodoItem
            notification={notification}
          />
        </div>
      ))
    );
  }
}
export default TodoList;
