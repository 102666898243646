import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <h4 className="wizard__description">
        1. Y'a-t-il une date impérative que doit couvrir la campagne ?
      </h4>
      <div className="form__form-group-field">
        <Field
          name="default_date"
          component={renderDatePickerField}
        />
        <div className="form__form-group-icon">
          <CalendarBlankIcon />
        </div>
      </div>
    </div>

    <div className="form__form-group">
      <h4 className="wizard__description">
        2. Seriez-vous d'accord pour que votre campagne démarre en décalage chez quelques commerçants, 
        dans le cas où certains auraient des vitrines indisponibles sur la période exacte de votre campagne ?
      </h4>
      <div className="form__form-group-field radio-double-height">
        <Field
          name="availability"
          component={renderRadioButtonField}
          label="Ok jusqu'à 1 semaine avant ou 1 semaine après"
          className="button"
          radioValue="1"
        />
        <Field
          name="availability"
          component={renderRadioButtonField}
          label="Ok jusqu'à 2 semaine avant ou 2 semaine après"
          className="button"
          radioValue="2"
        />
        <Field
          name="availability"
          component={renderRadioButtonField}
          label="Non je ne veux pas"
          className="button"
          radioValue="0"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Précédent</Button>
      <Button color="primary" type="submit" className="next">Suivant</Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
