import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';

const WizardFormOne = ({ handleSubmit, previousPage }) => (
  <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <h4 className="wizard__description">
        1. Après la campagne, nous questionnons les commerçants participants pour remonter de l'information 
        pertinente pour vous. Voulez-vous...
      </h4>
      <div className="form__form-group-field radio-double-height radio-large">
        <Field
          name="survey"
          component={renderRadioButtonField}
          label={`Que nous posions nos 10 questions "standard"`}
          className="button"
          radioValue="0"
        />
        <Field
          name="survey"
          component={renderRadioButtonField}
          label="Créer vos propres questions maintenant"
          className="button"
          radioValue="1"
        />
        <Field
          name="survey"
          component={renderRadioButtonField}
          label="Créer vos propres questions plus tard"
          className="button"
          radioValue="2"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Précédent</Button>
      <Button color="primary" type="submit">Valider le brief</Button>
    </ButtonToolbar>
  </form>
);

WizardFormOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
