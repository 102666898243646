import React, { PureComponent } from 'react';
import {
  Col, Card, Row, CardBody,
} from 'reactstrap';
import PropTypes, { object } from 'prop-types';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WizardFormFour from './WizardFormFour';
import WizardFormFive from './WizardFormFive';
import WizardFormSix from './WizardFormSix';
import WizardFormSeven from './WizardFormSeven';
import validate from './validate';

export default class WizardForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
      errors: {},
      toggleInputs: {
        recommandation: false
      }
    };
  }

  nextPage = (values) => {
    const errors = validate(values);
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      this.setState(prevState => ({ page: prevState.page + 1 }));
    }
  };

  previousPage = (values) => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  render() {
    const { onSubmit } = this.props;
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>général</p></div>
                <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>produit</p></div>
                <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}><p>timing</p></div>
                <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}><p>géographie</p></div>
                <div className={`wizard__step${page === 5 ? ' wizard__step--active' : ''}`}><p>commande commerçants</p></div>
                <div className={`wizard__step${page === 6 ? ' wizard__step--active' : ''}`}><p>cas particuliers</p></div>
                <div className={`wizard__step${page === 7 ? ' wizard__step--active' : ''}`}><p>sondage</p></div>
              </div>
              <div className="wizard__form-wrapper">
                {page === 1 && (
                  <WizardFormOne 
                    errors={this.state.errors} 
                    onSubmit={this.nextPage}
                  />
                )}
                {page === 2
                && (
                <WizardFormTwo
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
                )}
                {page === 3
                && (
                <WizardFormThree
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
                )}
                {page === 4
                && (
                <WizardFormFour
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
                )}
                {page === 5
                && (
                <WizardFormFive
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
                )}
                {page === 6
                && (
                <WizardFormSix
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
                )}
                {page === 7
                && (
                <WizardFormSeven
                  previousPage={this.previousPage}
                  onSubmit={onSubmit}
                />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
