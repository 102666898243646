import React from 'react';
import Panel from '../../../../../shared/components/Panel';

const ShopGeolocation = ({ population, density, commercialDensity }) => (
    <Panel md={12} lg={12} xl={3} title="Géographie">
      <div className="dashboard__stat dashboard__stat--budget">
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Population</p>
          <p className="dashboard__stat-main-number">{population}</p>
          <hr />
        </div>
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Densité</p>
          <p className="dashboard__stat-main-number">{density}</p>
          <hr />
        </div>
        <div className="dashboard__stat-main">
          <p className="dashboard__stat-main-title">Commerces dans un rayon de 1km</p>
          <p className="dashboard__stat-main-number">{commercialDensity}</p>
          <hr />
        </div>
      </div>
    </Panel>
  );

export default ShopGeolocation;
