import React from 'react';
import classNames from 'classnames';

export default ({ audience }) => {
  const amountClass = classNames({
    'dashboard__table-orders-amount': true,
    'dashboard__table-orders-amount--medium': audience <= 4000,
    'dashboard__table-orders-amount--low': audience <= 2000,
  });
  if (audience > 14999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  } if (audience > 12999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  } if (audience > 9999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  } if (audience > 6999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
  if (audience > 4499) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
  if (audience > 2999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
  if (audience > 1499) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
  if (audience > 999) {
    return (
      <div className={amountClass}>
        <div />
        <div />
        <div />
      </div>
    );
  }
  if (audience > 499) {
    return (
      <div className={amountClass}>
        <div />
        <div />
      </div>
    );
  }
  return (
    <div className={amountClass}>
      <div />
    </div>
  );
};